import { Parcel } from "@/lib/types/Parcel";
import { Crop } from "@/lib/types/Crop";
import { BEMeteoHistory, MeteoHistory, Precipitation, WaterCapacity, WaterCapacityCrop } from "@/lib/types/MeteoSeries";
import { ActionContext, ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module } from "..";
import { apiCall } from "@/lib/api";
import { parseDay, formatDate, logError } from "@/lib/util";

export interface MeteoState extends MeteoHistory {
  crop?: WaterCapacityCrop
}

function initialState(): MeteoState {
  return {
    crop: undefined,
    dailyPrecipitation: [],
    maizeWaterCapacity: [],
    wheatWaterCapacity: []
  }
}

const mutations = createMutations({
  clearMeteoHistory: (state: MeteoState) => {
    Object.assign(state, initialState())
  },
  setCrop: (state: MeteoState, crop: WaterCapacityCrop) => {
    state.crop = crop
  },
  setDailyPrecipitation: (state: MeteoState, payload: { data: Precipitation[] }) => {
    state.dailyPrecipitation = payload.data.map((day) => parseDay(day))
  },
  setWaterCapacity: (state: MeteoState, payload: { crop: WaterCapacityCrop, data: WaterCapacity[] }) => {
    const target = payload.crop + 'WaterCapacity'
    state[target] = payload.data.map((day) => parseDay(day))
  }
})

export type MeteoMutations = typeof mutations;

export interface MeteoGetters extends GetterMap {
  dailyPrecipitation: Precipitation[],
  waterCapacity: WaterCapacity[]
}

const getters: GetterTree<MeteoState, MeteoGetters> = {
  dailyPrecipitation(state, getter) {
    return state.dailyPrecipitation
  },
  waterCapacity: (state) => {
    if (state.crop === 'maize') return state.maizeWaterCapacity
    if (state.crop === 'wheat') return state.wheatWaterCapacity

    return []
  }
}

type Context = ActionContext<MeteoState, MeteoGetters>;

export interface MeteoActions extends ActionMap {
  fetchMeteoHistory: (parcel: Parcel) => Promise<void>
}

const actions: ActionTree<MeteoState, MeteoGetters, MeteoActions> = {
  async fetchMeteoHistory(context, parcel) {
    if (!parcel || !parcel.id) context.commit('clearMeteoHistory')

    const season = context.rootGetters.currentSeason
    const cropInterval = context.rootGetters.cropIntervalByDate

    if (cropInterval === null) {
      context.commit('clearMeteoHistory')
      return
    }

    const crop = context.rootGetters.allCrops[cropInterval.crop_id];

    try {
      const url = parcel.meteo_grid_point
        ? `/meteo-grid/${parcel.meteo_grid_point}/date_interval/`
        : `/farm/${parcel.farm}/season/${parcel.season}/parcel/${parcel.id}/meteo_history/`;
      const start_date = cropInterval.computed_start_date || formatDate(season.start_date);
      const end_date = cropInterval.computed_end_date || formatDate(season.end_date);

      const results = await apiCall<BEMeteoHistory>("GET", url, {
        start_date,
        end_date,
      });

      const cropName = crop.name.en.toLowerCase()
      const waterCapacityCrop: WaterCapacityCrop =
        (cropName.includes('maize') || cropName.includes('corn')) ? 'maize' :
        (cropName.includes('wheat')) ? 'wheat' : undefined
      ;

      context.commit('setCrop', waterCapacityCrop)
      context.commit('setDailyPrecipitation', { data: results.daily_precipitation })
      context.commit('setWaterCapacity', { crop: 'maize', data: results.maize_water_capacity })
      context.commit('setWaterCapacity', { crop: 'wheat', data: results.wheat_water_capacity })
    } catch (e) {
      context.commit('clearMeteoHistory')
      if (e.statusCode !== 404) {
        logError(e);
      }
    } finally {
      // These happen inside widget
      // this.isLoading = false;
      // this.initTooltips();
    }
  }
}

export default {
  strict: true,
  state: initialState(),
  mutations,
  actions,
  getters,
} as Module<MeteoState, MeteoGetters, MeteoActions>;
